<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Clinic Details">
        <clinicDetails :canEdit="false" />
      </vs-tab>
      <vs-tab label="Users & Accounts">
        <div>
          <OrganizationAccountsAndUsers :canAssignClinic="false" />
        </div>
      </vs-tab>
      <vs-tab label="Treatments">
        <treatmentList />
      </vs-tab>
      <vs-tab label="Invoices">
        <invoice-list :inTabEdit="true" :clinicInvoices="true" />
      </vs-tab>
      <vs-tab label="Orders">
        <clinic-orderList viewDetailRoute="OrderDetail" />
      </vs-tab>
      <vs-tab label="Settings">
        <clinicSettingsEditDetail />
      </vs-tab>
       <!-- <vs-tab label="Pos-Checkout">
      <pos-checkout/>
      </vs-tab>
       <vs-tab label="Pos-Transaction">
      <pos-transactions :clinicId="clinicId"/>
      </vs-tab>
       <vs-tab label="Pos-Settings">
       <pos-settings/>
      </vs-tab> -->
    </vs-tabs>
  </div>
</template>

<script>
import clinicDetails from "../../components/org-owner/clinics/clinicDetails.vue";
import treatmentList from "../../components/org-owner/clinics/treatments.vue";
import invoice from "../../organization/invoices/List.vue";
import ClinicOrderList from "../../components/order-center/order/ClinicOrderList.vue";
import clinicSettingsEditDetail from "../../components/clinics/ViewEditClinicSettings.vue";
import doctorTable from "../../components/doctors/doctorTable.vue";
import nurseTable from "../../components/nurses/nurseTable.vue";
import OrganizationAccountsAndUsers from "../../organization/users/index.vue";
import PosTransactions from '../../clinic/pos/PosTransactions.vue';
import PosSettings from '../../clinic/pos/PosSettings.vue';
import PosCheckout from '../../clinic/pos/PosCheckout.vue';


export default {
  components: {
    clinicDetails,
    treatmentList,
    "invoice-list": invoice,
    ClinicOrderList,
    clinicSettingsEditDetail,
    nurseTable,
    doctorTable,
    OrganizationAccountsAndUsers,
    PosTransactions,
    PosSettings,
    PosCheckout,
  },
  data: () => ({
    clinicId: ""
  }),
  created() {
    this.clinicId = this.$route.params.clinicId
  }
};
</script>

<style>
.invoice-list .con-ul-tabs {
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
}

.invoice-list .con-ul-tabs::-webkit-scrollbar {
  display: none;
}
</style>
